import { useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";

const CampaignName = (props) => {
  const navigate = useNavigate();

  ///// LOCATION /////
  const location = useLocation();
  const pathArray = location.pathname.split("/");
  const campaignId = pathArray.pop();


  const handleChange = (event) => {
    props.setData((oldArray) => {
      return { ...oldArray, campaign_name: event.target.value };
    });
  };

  const sendCampaignNameToNewCampaign = () => {
    console.log("=====agents====", props.agents, "=====agents====");
    if (props.cName !== "") {
      props.setActive(props.next);
    } else {
      console.log("Please Enter Campaign Name...");
    }

    // props.setData((oldArray) => {
    //   return { ...oldArray, campaignId: campaignId };
    // });
  };

  return (
    <div>
      <p className="text-sm text-slate-400">Step 1/6</p>
      <h1 className="text-lg font-bold">Let's Start with Campaign Details</h1>
      <p className="text-sm text-slate-400 border-b-2 pb-8">
        Please fill the details below, so that we can create new campaign
      </p>
      <h1 className="text-md text-slate-400 pt-8">Name</h1>
      <input
        type="text"
        id="message"
        name="message"
        onChange={handleChange}
        value={props.cName}
        className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg w-full h-12 p-2.5  "
        placeholder="Campaign Name"
        required
      />

      <div className="pt-8 flex justify-end items-end">
        <button
          className="font-bold text-lg rounded-full w-36 h-12"
          onClick={() => navigate("/admin/campaign/list")}
        >
          Back
        </button>
        <button
          className="font-bold text-lg rounded-full w-36 h-12"
          style={{
            backgroundImage:
              "linear-gradient(177.88deg, #AFD462 1.79%, #6BC698 49.97%, #0FB1E2 82.06%)",
            color: "white",
          }}
          onClick={sendCampaignNameToNewCampaign}
        >
          Next Step
        </button>
      </div>
    </div>
  );
};
export default CampaignName;
