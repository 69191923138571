import "reactjs-popup/dist/index.css";

function ScheduleTime(props) {
  const handleChange = (e) => {
    props.setData((oldArray) => {
      return { ...oldArray, schedule_time: e.target.value };
    });
  };

  const sendScheduleTimeToNewCampaign = () => {
    if (props.scheduleTime !== "") {
      props.setActive(props.next);
    }
  }; 

  return (
    <div>
      <p className="text-sm text-slate-400">Step 3/6</p>
      <h1 className="text-lg font-bold">Let's Schedule Time</h1>
      <p className="text-sm text-slate-400 border-b-2 pb-8">
        Please schedule time, so that we can create new campaign
      </p>
      <h1 className="text-md text-slate-400 pt-8">Schedule</h1>
      <div className="grid md:grid-cols-7">
        <input
          type="datetime-local"
          id="datetime-local"
          name="startTime"
          onChange={handleChange}
          value={props.scheduleTime}
          className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg w-[22rem] h-12 p-2.5  "
          required
          min={new Date().toISOString().slice(0, 16)}

        />
      </div>
      <div className="pt-8 flex justify-end items-end mr-[44rem]">
        <button
          className="font-bold text-lg rounded-full w-36 h-12"
          onClick={() => {
            props.setActive(props.back);
          }}
        >
          Back
        </button>
        <button
          className="font-bold text-lg rounded-full w-36 h-12"
          style={{
            backgroundImage:
              "linear-gradient(177.88deg, #AFD462 1.79%, #6BC698 49.97%, #0FB1E2 82.06%)",
            color: "white",
          }}
          onClick={sendScheduleTimeToNewCampaign}
        >
          Next Step
        </button>
      </div>
    </div>
  );
}
export default ScheduleTime;
