import React, { useState } from "react";

const Pagination = (props) => {
    const [page, setPage] = useState(1)
  return (
    <>
      {/* <div className="flex items-center justify-center lg:px-0 sm:px-6 px-4">
        <div className=" w-full  flex items-center justify-between border-t border-gray-200 ">
          <div className="flex items-center pt-3 text-gray-600 dark:text-gray-200  hover:text-indigo-700 cursor-pointer">
            <svg
              width="14"
              height="8"
              viewBox="0 0 14 8"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M1.1665 4H12.8332"
                stroke="currentColor"
                strokeWidth="1.25"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M1.1665 4L4.49984 7.33333"
                stroke="currentColor"
                strokeWidth="1.25"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M1.1665 4.00002L4.49984 0.666687"
                stroke="currentColor"
                strokeWidth="1.25"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
            <p
              className="text-sm ml-3 font-medium leading-none"
              onClick={(e) => props.PaginationControl("first", "")}
            >
              First
            </p>
          </div>

          <div className="sm:flex hidden">
            {Array.from(
              { length: props?.pagination_data?.total_pages || 0 },
              (_, index) => (
                <p
                  onClick={(e) => props.PaginationControl("page", index + 1)}
                  className={`text-sm font-medium leading-none cursor-pointer ${
                    props?.pagination_data?.current_page == index + 1
                      ? "text-indigo-700 dark:text-indigo-400 border-t border-indigo-400 pt-3 mr-4 px-2"
                      : " text-gray-600 dark:text-gray-200  hover:text-indigo-700 dark:hover:text-indigo-400 border-t border-transparent hover:border-indigo-400 pt-3 mr-4 px-2"
                  }`}
                >
                  {index + 1}
                </p>
              )
            )}
          </div>

          <div className="flex items-center pt-3 text-gray-600 dark:text-gray-200  hover:text-indigo-700 cursor-pointer">
            <p
              className="text-sm font-medium leading-none mr-3"
              onClick={(e) => props.PaginationControl("last", "")}
            >
              Last
            </p>
            <svg
              width="14"
              height="8"
              viewBox="0 0 14 8"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M1.1665 4H12.8332"
                stroke="currentColor"
                strokeWidth="1.25"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M9.5 7.33333L12.8333 4"
                stroke="currentColor"
                strokeWidth="1.25"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M9.5 0.666687L12.8333 4.00002"
                stroke="currentColor"
                strokeWidth="1.25"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
          </div>
        </div>
      </div> */}

      {/*----------------------------------------- PAGINATION --------------------------------------------- */}

      <div className="flex justify-between">
        <div className="flex space-x-3">
          <a
            href="#"
            className="flex space-x-2 items-center justify-center px-3 h-8 me-3 text-sm font-medium text-gray-500 bg-white border border-gray-300 rounded-lg hover:bg-gray-100 hover:text-gray-700  "
            onClick={(e) => props.PaginationControl("prev", "")}
          >
            <svg
              className="w-3.5 h-3.5 me-2 rtl:rotate-180"
              aria-hidden="true"
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 14 10"
            >
              <path
                stroke="currentColor"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokwidth="2"
                d="M13 5H1m0 0 4 4M1 5l4-4"
              />
            </svg>
            <h1>Previous</h1>
          </a>
          <a
            href="#"
            className="flex space-x-2 items-center justify-center px-3 h-8 me-3 text-sm font-medium text-gray-500 bg-white border border-gray-300 rounded-lg hover:bg-gray-100 hover:text-gray-700  "
            onClick={(e) => props.PaginationControl("next", "")}
          >
            <h1>Next</h1>
            <svg
              className="w-3.5 h-3.5 ms-2 rtl:rotate-180"
              aria-hidden="true"
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 14 10"
            >
              <path
                stroke="currentColor"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokwidth="2"
                d="M1 5h12m0 0L9 1m4 4L9 9"
              />
            </svg>
          </a>
        </div>
        <div className="flex space-x-3">
          <button
            type="button"
            className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 "
          >
            {props.pagination_data.total_pages}
          </button>

          <input
            type="text"
            id="page_no"
            className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 "
            placeholder="Page Number"
            onChange={(e) => {
              setPage(e.target.value);
            }}
          />
          <button
            type="submit"
            className="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm w-full sm:w-auto px-5 py-2.5 text-center"
            onClick={(e) => props.PaginationControl("page",page)}
          >
            Go
          </button>
        </div>
        <div className="flex space-x-3">
          <a
            href="#"
            className="flex space-x-2 items-center justify-center px-3 h-8 text-sm font-medium text-gray-500 bg-white border border-gray-300 rounded-lg hover:bg-gray-100 hover:text-gray-700  "
            onClick={(e) => props.PaginationControl("first", "")}
          >
            <svg
              className="w-3.5 h-3.5 me-2 rtl:rotate-180"
              aria-hidden="true"
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 14 10"
            >
              <path
                stroke="currentColor"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokwidth="2"
                d="M13 5H1m0 0 4 4M1 5l4-4"
              />
            </svg>
            <h1>First</h1>
          </a>

          <a
            href="#"
            className="flex space-x-2 items-center justify-center px-3 h-8 me-3 text-sm font-medium text-gray-500 bg-white border border-gray-300 rounded-lg hover:bg-gray-100 hover:text-gray-700  "
            onClick={(e) => props.PaginationControl("last", "")}
          >
            <h1>Last</h1>
            <svg
              className="w-3.5 h-3.5 ms-2 rtl:rotate-180"
              aria-hidden="true"
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 14 10"
            >
              <path
                stroke="currentColor"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokwidth="2"
                d="M1 5h12m0 0L9 1m4 4L9 9"
              />
            </svg>
          </a>
        </div>
      </div>
    </>
  );
};

export default Pagination;
