import React, { useState, useEffect } from "react";
import Feedback01 from "./Feedback01";
import Feedback02 from "./Feedback02";
import Feedback03 from "./Feedback03";
import useFetch from "../../hooks/useFetch";
import { ReactComponent as Cross } from "../../assets/images/svg/Cross.svg";
import { FEEDBACK } from "../../utils/ApiUrls";
import { TopDisappearingMessage } from "../../utils/Common";
import useAuth from "../../hooks/useAuth";
import useSocket from "../../hooks/useWebsocket";
import { ReactComponent as Edit } from "../../assets/images/svg/Edit.svg";
import { CheckCircle } from "lucide-react"; // Importing a custom icon from Lucide


const CallFeedback = (props) => {
  const [curfback, setcurrfback] = useState("Feedback02");
  const FBdata = props.FBdata;
  const setFBdata = props.setFBdata;
  const fetchData = useFetch();
  const { auth } = useAuth();
  const [isReady, val, send] = useSocket();
  const viewfbvalinitial = props?.viewfb || false;
  const [viewfb, setviewfb] = useState(false);
  const [editfbpermission, seteditfbpermission] = useState(false);
  console.log("Feedback js   customer data    :    ", props.camp_data);

  useEffect(() => {
    if (auth?.user_details?.type === "agent") {
      seteditfbpermission(true);
    } else {
      seteditfbpermission(true);
    }
  }, []);

  useEffect(() => {
    const data = val?.data || { callstatus: "" };
    const action = val?.action;
    console.log("Feedback Value ---->  : ", val)
    console.log("Feedback Data ---->  : ", data)
    console.log(
      typeof data.closeInSeconds,
      "Feedback Hide Tiem --------------------------------->   : ",
      data.closeInSeconds
    );
    if (props.camp_data.campaign_mode == "auto") {
      if (data.callstatus == "end") {
        TopDisappearingMessage(
          "top-start",
          data.closeInSeconds,
          "warning",
          "Next customer in " + data.closeInSeconds + " seconds"
        );

        // setTimeout(function () {
        //   // submitData();
        //   props.setfback(false);
        //   console.log("Send socket message next call auto")
        //   send(
        //     JSON.stringify({
        //       type: "feedback_close",
        //       next_call : true,
        //     })
        //   );

        // }, parseInt(data.closeInSeconds) * 1000);
      }
    }
  }, [val]);

  const CloseFB = () => {
    props.setfback(false);
    props.close(false);
  };

  console.log("before submit feedback customer ---------------->  ", props?.follwUpFeedback)

  const submitData = async () => {
    console.log("Feedback Submitted ! ",props.setFeedbackSubmitted)
    let user_id;
    props.setfback(false);
    props.close(false);
    if (props.setFeedbackSubmitted != undefined) {
      props.setFeedbackSubmitted(true);
    }

    if (auth?.user_details?.type == "agent") {
      user_id = auth?.user_details?.id;
    } else {
      user_id = props.FBdata.agent_id || 0;
    }

    let customer_data = props.customer

    if (props.customer.id == undefined) {
      customer_data = localStorage.getItem("customer");
    }

    console.log("CUSTOMER DATA ------>  : ",customer_data,"-------",customer_data.id)

    let url = FEEDBACK + user_id + "/" + customer_data.id + "/";
    console.log("Feedback Customer   : ",props.customer)
    console.log("Feedback submit url : ", url)
    console.log("Feedback data   :  ",FBdata)
    const res_data = await fetchData("post", FBdata, url);
    if (res_data?.data == "success") {
      console.log("Campaign report data   :  ",props.campaignRepotData);
      if (props.campaignRepotData != undefined) {
        const updatedData = props.campaignRepotData.map((item) => {
          if (item && props.FBdata && item.call_uid === props.FBdata.uid) {
            return {
              ...item,
              call_feedback:
                item.call_feedback === null ? res_data?.id : item.call_feedback,
            };
          }
          return item;
        });
        props.setCampaignReportData(updatedData);
      }

      setFBdata((prevState) => {
        return { ...prevState, id: res_data?.id };
      });
      TopDisappearingMessage(
        "top-start",
        2000,
        "success",
        "Successfully submited the feedback",
        true
      );
    } else {
      alert("Error in Updating the agent status");
    }
  };

  return (
    <div id="defaultModal"
      className="fixed inset-0 flex items-center justify-center p-4 overflow-y-auto bg-black bg-opacity-50">
      <div className="w-full h-[32rem] flex justify-center space-x-1">
        {props.followUp ? (
          <div className="relative sm:left-0 lg:bottom-[5rem] lg:top-[5rem] bg-white rounded-lg shadow-lg w-[25%] p-4">
            <div className={`bg-back-white rounded-md `}>
              <h1 className="font-bold text-lg p-[5px] rounded-[5px] bg-green-200">Feedback History</h1>
            </div>
            <div className="grid gap-4 mb-4 grid-cols-2 h-[28rem] overflow-auto">
              <div className="col-span-2">
                {props.follwUpFeedback.map((feedback_history) => (
                  <a href="#" class="block max-w-auto p-2 bg-white border border-gray-200 rounded-lg shadow-sm hover:bg-green-50 mt-3">
                    <div className="flex">
                      <h1><b>Date : </b></h1>
                      <div className="ml-4">{feedback_history?.created_at}</div>
                    </div>
                    <h3 class="mb-2 text-l font-bold tracking-tight">Questions</h3>
                    <ul className="p-0 w-full text-sm text-gray-900">
                      {Object.entries(feedback_history?.feedback_questions || {}).map(([key, value]) => (
                        <li
                          key={key}
                          className="flex items-center gap-2 p-1"
                        >
                          <CheckCircle className="w-4 h-4 text-blue-500" /> {/* Custom bullet icon */}
                          <span>
                            <strong>{key} <br></br></strong> {value}
                          </span>
                        </li>
                      ))}
                    </ul>

                    <h3 class="mb-2 text-l font-bold tracking-tight">Labels</h3>
                    <ul className="p-0 w-full text-sm text-gray-900">
                      {Object.entries(feedback_history?.feedback_labels || {}).map(([key, value]) => (
                        <li
                          key={key}
                          className="flex items-center gap-2 p-1"
                        >
                          <CheckCircle className="w-4 h-4 text-blue-500" /> {/* Custom bullet icon */}
                          <span>
                            <strong>{key} <br></br></strong> {value}
                          </span>
                        </li>
                      ))}
                    </ul>
                    <h2><b>Comment</b></h2>
                    <div className="ml-4">{feedback_history?.comment}</div>
                  </a>
                ))}
              </div>
            </div>
          </div>
        ) : ""}

        <div
          className="relative sm:left-0 lg:bottom-[5rem] lg:top-[5rem] bg-white rounded-lg shadow-lg w-[35%] p-4"
        >
          <div className="w-full bg-gray-200 rounded-full h-[2px]">
            <div
              className="bg-green-400 h-[2px] rounded-full"
              style={{
                width:
                  curfback === "Feedback01"
                    ? "33%"
                    : curfback === "Feedback02"
                      ? "67%"
                      : "100%",
              }}
            ></div>
          </div>

          {/* Modal Content */}
          <div className="space-y-2 mt-3">
            <div className="flex justify-between bg-green-200 p-[5px] rounded-[5px]">
              <h1 className="font-bold text-lg">Add Feedback</h1>
              <div className="flex gap-4">
                <button className="ml-auto" onClick={CloseFB}>
                  <Cross />
                </button>
                {editfbpermission && (
                  <button className="w-[20px]" onClick={() => setviewfb(false)}>
                    <Edit
                      className="w-6 h-6 text-blue-600"
                      viewBox="0 0 26 26"
                      fill="currentColor"
                    />
                  </button>
                )}
              </div>
            </div>
            <div className="flex space-x-5">
              <p>Name : </p>
              <h1><b>{props.customer.customer_name || ""}</b></h1>
              <p>Phone Number : </p>
              <h1><b>{props.customer.customer_phone_number || ""} </b></h1>
            </div>
            <div className="overflow-auto h-[25rem]">
              {/* Feedback Components */}
              {curfback === "Feedback01" ? (
                <Feedback01
                  customer={props.customer}
                  setcurrfback={setcurrfback}
                  next="Feedback02"
                  showCustomerNo={props.showCustomerNo}
                />
              ) : curfback === "Feedback02" && props?.camp_data?.questionArray ? (
                <Feedback02
                  answers={FBdata.answers}
                  setcurrfback={setcurrfback}
                  setFBdata={setFBdata}
                  questions={props?.camp_data?.questionArray || []}
                  next="Feedback03"
                  back="Feedback01"
                />
              ) : (
                <Feedback03
                  fbanswer={FBdata.fbanswer}
                  submitData={submitData}
                  FBdata={FBdata}
                  setcurrfback={setcurrfback}
                  setFBdata={setFBdata}
                  feedback={props?.camp_data?.feedBackLabels || []}
                  back="Feedback02"
                  editfbpermission={editfbpermission}
                />
              )}
            </div>
          </div>
        </div>
        {/* </div> */}
      </div>
    </div>

  );
};

export default React.memo(CallFeedback);
