import React from "react";

import { useEffect, useState } from "react";
import Pagination from "../../components/Table/pagination";
import axios from "axios";
import useAuth from "../../hooks/useAuth";
import { Link } from "react-router-dom";
import { ReactComponent as Edit } from "../../assets/images/svg/Edit.svg";
import { ReactComponent as Delete } from "../../assets/images/svg/Delete.svg";
import Swal from "sweetalert2";
import UseAxiosPrivate from "../../hooks/useAxiosPrivate";
import { useNavigate, useLocation } from "react-router-dom";

const Agents = () => {
  const { auth } = useAuth();
  const theadData = [
    "Sl-No",
    "Agent",
    "AgentStatus",
    "Virtual ID",
    "Vsip Password",
    "CallMode",
    "CallType",
    "Action",
  ];

  const [agents, setagents] = useState([]);
  const [Agents, setAgents] = useState([]);
  const axiosPrivate = UseAxiosPrivate();
  const navigate = useNavigate();
  const location = useLocation();

  // -------------------------------- PAGINATION -------------------------------- //
  const Initial_pagination = {
    next: "",
    previous: "",
    count: "",
    total_pages: "",
    current_page: 1,
    page_size: 5,
  };

  const [pagination, setPagination] = useState(Initial_pagination);

  const PaginationControl = (type, number) => {
    if (type == "page") {
      if (number <= pagination.total_pages) {
        getAgents(number,"");
      } else {
        getAgents(1,"");
      }
    } else if (type == "last") {
      getAgents(pagination.total_pages,"");
    } else if (type == "prev") {
      if (pagination.current_page > 1) {
        getAgents(pagination.current_page - 1,"");
      }
    } else if (type == "next") {
      if (pagination.current_page < pagination.total_pages) {
        getAgents(pagination.current_page + 1,"");
      }
    } else {
      getAgents(1,"");
    }
  };
  // -------------------------------- END ---------------------------------------//
  const controller = new AbortController();
  let isMounted = true;

  const getAgents = async (pagenumber,searchText) => {
    const agent_post_data = {
      user_id: auth?.user_details?.id,
      page: pagenumber,
      search_text:searchText
    };
    try {
      const response = await axiosPrivate.get(
        process.env.REACT_APP_API_URL + "/contactcentre/agents/",
        { params: agent_post_data },
        {
          signal: controller.signal,
        }
      );

      console.log(response.data);
      isMounted && setagents(response.data.results);
      setAgents(response.data.results);

      setPagination((prevState) => {
        return {
          ...prevState,
          total_pages: response.data?.total_pages || 0,
          previous: response.data?.previous || null,
          next: response.data?.next || null,
          current_page: response.data?.current_page || 0,
          count: response.data?.count || 0,
          page_size: response.data?.page_size || 5,
        };
      });
    } catch (err) {
      console.error(err);
      navigate("/login", { state: { from: location }, replace: true });
    }
  };

  useEffect(() => {
    getAgents(1,"");

    return () => {
      isMounted = false;
      controller.abort();
    };
  }, []);

  const AgentDelete = async (agent_id, index) => {
    Swal.fire({
      title: "Are you sure to Delete?",
      text: "This Agent will be deleted from your campaign",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Delete",
    }).then(async (result) => {
      if (result.isConfirmed) {
        console.log("=====camp data===", agents);

        setagents((current) =>
          current.filter((fruit) => fruit.id !== agent_id)
        );

        let post_data = {
          user_id: auth?.user_details?.user,
          agent_id: agent_id,
        };

        let config = {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${auth?.accessToken}`,
          },
        };

        await axios
          .post(
            process.env.REACT_APP_API_URL + "/contactcentre/delete_agent/",
            post_data,
            config
          )
          .then((res) => {
            console.log("=camp list loaded=", res.data);
          })
          .catch((err) => console.log(err));
      }
    });
  };

  const [searchText, setSearchText] = useState("");

 const handleChange = (event) => {
    setSearchText(event.target.value);
  };

  const searchAgent = async () => {
    getAgents(1,searchText)
  };

  return (
    <>
      <div className="flex flex-col bg-white">
        <div className="flex justify-between p-3">
          <div className="flex justify-items-start">
              <div className="relative">
                <div className="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
                  <svg
                    className="w-5 h-5 text-gray-500 "
                    fill="currentColor"
                    viewBox="0 0 20 20"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      fillRule="evenodd"
                      d="M8 4a4 4 0 100 8 4 4 0 000-8zM2 8a6 6 0 1110.89 3.476l4.817 4.817a1 1 0 01-1.414 1.414l-4.816-4.816A6 6 0 012 8z"
                      clipRule="evenodd"
                    ></path>
                  </svg>
                </div>
                <input
                  type="text"
                  id="table-search"
                  className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-80 pl-10 p-2.5  "
                  placeholder="Search for items"
                  onChange={handleChange}
                />
              </div>
              <div className="ml-2">
                <button type="button" class="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 me-2 mb-2 dark:bg-blue-600 dark:hover:bg-blue-700 focus:outline-none dark:focus:ring-blue-800" onClick={searchAgent}>Search</button>
              </div>
          </div>
          <Link to="/admin/agent/create/0">
            <button
              type="button"
              className="inline-block px-6 py-2.5 bg-green-500 text-white font-medium text-xs leading-tight uppercase rounded shadow-md hover:bg-green-600 hover:shadow-lg focus:bg-green-600 focus:shadow-lg focus:outline-none focus:ring-0 active:bg-green-700 active:shadow-lg transition duration-150 ease-in-out"
            >
              + ADD AGENTS
            </button>
          </Link>
        </div>

        <div className="py-2 inline-block min-w-full ">
          <div className="overflow-x-auto">
            <table className="w-full">
              <thead>
                <tr className="text-[#9AA7B5] text-[17px] font-karla font-[500]">
                  {theadData.map((item) => {
                    return (
                      <th
                        key={item}
                        title={item}
                        className="font-[500] pl-[2.1rem] pr-[1.5rem] text-start"
                      >
                        {item}
                      </th>
                    );
                  })}
                </tr>
              </thead>

              <tbody>
                {""}
                {agents ? (
                  <>
                    {agents.map((agent, index) => (
                      <tr
                        className="border-b text-[16px] font-karla text-gray-900  whitespace-nowrap"
                        key={
                          0 +
                          index +
                          (pagination?.current_page - 1) * pagination?.page_size
                        }
                      >
                        <td className="py-4 pl-[2.1rem] pr-[1.5rem]">
                          0
                          {index +
                            1 +
                            (pagination?.current_page - 1) *
                              pagination?.page_size}
                        </td>
                        <td className="pl-[2.1rem] pr-[1.5rem]  py-4 ">
                          {agent.agent_username}
                          <br></br>
                          {agent.phone_number}
                        </td>

                        {agent.current_active_status === "logout" && (
                          <td className="pl-[2.1rem] pr-[1.5rem]  py-4">
                            <span className="inline-flex items-left gap-1 rounded-full bg-yellow-50 px-2 py-1 text-xs font-semibold  text-[#fbbf24]">
                              <span className="h-1.5 w-1.5 rounded-full bg-[#fbbf24]"></span>
                              Logout
                            </span>
                          </td>
                        )}

                        {agent.current_active_status === "" && (
                          <td className="pl-[2.1rem] pr-[1.5rem]  py-4">
                            <span className="inline-flex items-left gap-1 rounded-full bg-yellow-50 px-2 py-1 text-xs font-semibold  text-[#fbbf24]">
                              <span className="h-1.5 w-1.5 rounded-full bg-[#fbbf24]"></span>
                              Logout
                            </span>
                          </td>
                        )}

                        {agent.current_active_status === "unavailable" && (
                          <td className="pl-[2.1rem] pr-[1.5rem]  py-4">
                            <span className="inline-flex items-center gap-1 rounded-full bg-red-50 px-2 py-1 text-xs font-semibold text-red-600">
                              <span className="h-1.5 w-1.5 rounded-full bg-red-600"></span>
                              Unavailable
                            </span>
                          </td>
                        )}

                        {agent.current_active_status === "break" && (
                          <td className="pl-[2.1rem] pr-[1.5rem]  py-4">
                            <span className="inline-flex items-center gap-1 rounded-full bg-blue-50 px-2 py-1 text-xs font-semibold text-blue-600">
                              <span className="h-1.5 w-1.5 rounded-full bg-blue-600"></span>
                              Break
                            </span>
                          </td>
                        )}
                        {agent.current_active_status === "login" && (
                          <td className="pl-[2.1rem] pr-[1.5rem]  py-4  text-purple-600">
                            <span className="inline-flex items-center gap-1 rounded-full bg-purple-50 px-2 py-1 text-xs font-semibold text-purple-600">
                              <span className="h-1.5 w-1.5 rounded-full bg-purple-600"></span>
                              Login
                            </span>
                          </td>
                        )}

                        {agent.current_active_status === "available" && (
                          <td className="pl-[2.1rem] pr-[1.5rem]  py-4  text-[#26ae0b]">
                            <span className="inline-flex items-center gap-1 rounded-full bg-green-50 px-2 py-1 text-xs font-semibold text-green-600">
                              <span className="h-1.5 w-1.5 rounded-full bg-green-600"></span>
                              Available
                            </span>
                          </td>
                        )}

                        {agent.current_active_status === "waiting" && (
                          <td className="pl-[2.1rem] pr-[1.5rem]  py-4  text-[#26ae0b]">
                            <span className="inline-flex items-center gap-1 rounded-full bg-pink-50 px-2 py-1 text-xs font-semibold text-pink-600">
                              <span className="h-1.5 w-1.5 rounded-full bg-pink-600"></span>
                              Waiting
                            </span>
                          </td>
                        )}

                        <td className="pl-[2.1rem] pr-[1.5rem]  py-4 ">
                          {agent.vsip_id || "-"}{" "}
                        </td>

                        <td className="pl-[2.1rem] pr-[1.5rem]  py-4 ">
                          {agent.vsip_password || "-"}{" "}
                        </td>

                        <td className="pl-[2.1rem] pr-[1.5rem]  py-4 ">
                          {agent.call_mode || "-"}{" "}
                        </td>

                        <td className="pl-[2.1rem] pr-[1.5rem]  py-4 ">
                          {agent.call_options || "-"}{" "}
                        </td>

                        <>
                          <td className="p-3 flex">
                            <div className="flex justify-between">
                              <Link to={`/admin/agent/create/${agent.id}`}>
                                <div className="flex  rounded-md p-2 cursor-pointer hover:bg-light-white text-gray-300 text-sm items-center gap-x-4">
                                  <Edit
                                    className={`width="26" height="26" fill="blue"`}
                                    viewBox="0 0 26 26"
                                    fill="green"
                                    cx="8.5"
                                    cy="8.5"
                                    r="6"
                                    fillRule="evenodd"
                                    fillOpacity=".8"
                                  />
                                </div>
                              </Link>
                              <div
                                onClick={() => {
                                  AgentDelete(agent.id, "stop", index);
                                }}
                                className="flex  rounded-md p-2 cursor-pointer hover:bg-light-white text-gray-300 text-sm items-center gap-x-4"
                              >
                                <Delete
                                  className={`width="26" height="26" fill="blue"`}
                                  viewBox="0 0 26 26"
                                  fill="red"
                                  cx="8.5"
                                  cy="8.5"
                                  r="6"
                                  fillRule="evenodd"
                                  fillOpacity=".8"
                                />
                              </div>
                            </div>
                          </td>
                        </>
                      </tr>
                    ))}
                  </>
                ) : null}
              </tbody>
            </table>
          </div>
        </div>

        <div className="p-8">
          <Pagination
            pagination_data={pagination}
            PaginationControl={PaginationControl}
          />
        </div>
      </div>
    </>
  );
};

export default Agents;
