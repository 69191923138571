import { ReactComponent as Cross } from "../../assets/images/svg/Cross.svg";
import { ReactComponent as Recall } from "../../assets/images/svg/ReCall.svg";
import React, { useEffect, useState } from "react";
import DatePicker from "react-datepicker";

import "react-datepicker/dist/react-datepicker.css";

function Feedback03(props) {
  const [feedbacks, setFeedbacks] = useState([]);

  console.log("<<<<<<<<<<", props.FBdata, ">>>>>>>>>>>>");

  useEffect(() => {
    setFeedbacks(props.feedback);
  }, [props.feedback]);

  const updateFBAnswer = (e, label) => {
    if (e.target.checked) {
      props.setFBdata((prevState) => ({
        ...prevState,
        fbanswer: { ...prevState.fbanswer, [label]: e.target.value },
      }));
    }
  };

  const updateComment = (value) => {
    props.setFBdata((prevState) => {
      return { ...prevState, comment: value };
    });
  };

  const updateFollowUp = (value) => {
    props.setFBdata((prevState) => {
      return { ...prevState, folloup: value };
    });
  };

  return (
    <>
      {feedbacks.map((feedback, index) => (
        <div>
          <h1 className="text-gray-400 text-md">{feedback.label}</h1>
          <div className="justify-around border h-[6rem] border-gray-300 rounded-lg p-4 mt-4 overflow-y-auto">
            {feedback.option.map(function (item) {
              return (
                <div className="form-check  form-check-inline" key={item}>
                  <input
                    className="form-check-input form-check-input appearance-none rounded-full h-4 w-4 border border-gray-300 bg-white
                               checked:bg-blue-600 checked:border-blue-600 focus:outline-none transition duration-200 mt-1 align-top bg-no-repeat bg-center bg-contain float-left mr-2 cursor-pointer"
                    type="radio"
                    name={index + "_icon"}
                    checked={
                      props.FBdata?.fbanswer[feedback.label] == item
                        ? true
                        : false
                    }
                    id="inlineRadio1"
                    value={item}
                    disabled={props?.viewfb || false}
                    onChange={(event) => {
                      updateFBAnswer(event, feedback.label);
                    }}
                  />
                  <label
                    className="form-check-label inline-block text-gray-800 text-[17px] fon"
                    htmlFor="inlineRadio10"
                  >
                    {item}
                  </label>
                </div>
              );
            })}
          </div>
        </div>
      ))}

      <div key={props.FBdata?.uid}>
        <h1 className="text-gray-400 text-md">Comment</h1>
        <input
          type="text"
          id="Answer"
          value={props.FBdata?.comment || ""}
          className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg w-full h-28 p-2.5   "
          onChange={(e) => {
            updateComment(e.target.value);
          }}
          disabled={props?.viewfb || false}
          required
        />
      </div>

      <div>
        <h1 className="text-gray-400 text-md">Follow up Date</h1>
        <div>
          <div className="datepicker relative form-floating mb-3 w-full">
            <DatePicker
              className="form-control block w-full px-3 py-1.5 text-base font-normal text-gray-700 bg-white bg-clip-padding border border-solid border-gray-300 rounded transition ease-in-out m-0"
              selected={props?.FBdata?.folloup ? new Date(props.FBdata.folloup) : null}
              onChange={(date) => updateFollowUp(date)}
              placeholderText="Select a date" // Optional placeholder
              disabled={props?.viewfb || false}
            />
          </div>
        </div>
      </div>

      <div className="flex place-content-between">
        <div className=""></div>
        <div className="">
          <button
            onClick={() => {
              props.setcurrfback(props.back);
            }}
            className="font-bold text-lg rounded-full w-36 h-12 mt-12 items-end"
          >
            Back
          </button>
          {props?.editfbpermission && (
            <button
              onClick={() => {
                props.submitData();
              }}
              className="font-bold text-lg rounded-full w-36 h-12 mt-12 items-end"
              style={{
                backgroundImage:
                  "linear-gradient(177.88deg, #AFD462 1.79%, #6BC698 49.97%, #0FB1E2 82.06%)",
                color: "white",
              }}
            >
              Complete
            </button>
          )}
        </div>
      </div>
    </>
  );
}
export default Feedback03;
