import React, { createContext, useEffect, useRef, useState } from "react";
import { w3cwebsocket as W3CWebSocket } from "websocket";
import useAuth from "../hooks/useAuth";

const WebsocketContext = createContext({});

export const WebsocketProvider = ({ children }) => {
  const [isReady, setIsReady] = useState(false);
  const [val, setVal] = useState(null);
  const { auth } = useAuth();
  const ws = useRef(null);
  const heartbeatInterval = useRef(null);
  const heartbeatTimeout = useRef(null);

  const [diconnect, setDisconnect] = useState(false);

  useEffect(() => {
    let user_id = "";
    if (auth?.user_details?.type === "agent") {
      user_id = auth?.user_details?.useragent;
    } else {
      user_id = auth?.user_details?.user;
    }

    function connect() {
      console.log("======== WebSocket URL =========", process.env.REACT_APP_WS_URL);
      const socket = new W3CWebSocket(process.env.REACT_APP_WS_URL + "ws/campaign/" + user_id + "/");

      socket.addEventListener("open", function () {
        console.log("WebSocket connected");
        setIsReady(true);
        startHeartbeat(socket);
      });

      socket.addEventListener("message", function (message) {
        if (message.data === "pong") {
          console.log("✅ Heartbeat received");
          setDisconnect(false)
          clearTimeout(heartbeatTimeout.current);
        } else {
          setVal(JSON.parse(message.data));
        }
      });

      socket.addEventListener("close", function () {
        console.log("WebSocket disconnected ❌");
        setIsReady(false);
        setDisconnect(true);
        stopHeartbeat();
        setTimeout(connect, 2000); // Reconnect after 2 seconds
      });

      socket.onerror = (e) => {
        console.error("WebSocket error:", e);
      };

      ws.current = socket;
    }

    connect();

    return () => {
      stopHeartbeat();
      if (ws.current) {
        ws.current.close();
      }
    };
  }, []);

  /** Start the Heartbeat Mechanism **/
  function startHeartbeat(socket) {
    heartbeatInterval.current = setInterval(() => {
      if (socket.readyState === WebSocket.OPEN) {
        console.log("💓 Sending heartbeat...");
        socket.send("ping");

        heartbeatTimeout.current = setTimeout(() => {
          console.warn("🚨 No heartbeat response, closing WebSocket...");
          socket.close(); // Force close if no response
        }, 5000);
      }
    }, 10000); // Ping every 10 seconds
  }

  /** Stop Heartbeat on Disconnect **/
  function stopHeartbeat() {
    clearInterval(heartbeatInterval.current);
    clearTimeout(heartbeatTimeout.current);
  }

  const ret = [isReady, val, ws.current?.send.bind(ws.current),diconnect];

  return <WebsocketContext.Provider value={ret}>{children}</WebsocketContext.Provider>;
};

export default WebsocketContext;
