import React, { useState } from "react";
import { ReactComponent as LogoIcon } from "../assets/images/logo/Logo.svg";
import { ReactComponent as HomeIcon } from "../assets/icons/home.svg";
import { Link } from "react-router-dom";
import useAuth from "../hooks/useAuth";
import Adminroutes, { AgentRoutes, CommonRoutes } from "../routes";
import { Icon } from "../routes";

import { createContext } from "react";

import UseAxiosPrivate from "../hooks/useAxiosPrivate";
import { AgentReport } from "../pages";

const Sidebar = (props) => {
  const [open, setOpen] = useState(false);

  // Get User id //
  const user = useAuth();
  const user_id = user.auth.user_details.id;

  // API CALL//
  const { auth } = useAuth();
  const axiosprivate = UseAxiosPrivate();

  // const AgentReport = Adminroutes[5].component.props; 

  // const [AgentReportData, setAgentReportData] = useState(AgentReport);

  // const getDataFromBackend = async () => {
  //   const response = await axiosprivate.get(
  //     "/contactcentre/agentReport/" + user_id + "/",
  //     {
  //       headers: {
  //         "Content-Type": "application/json",
  //         Authorization: `Bearer ${auth?.accessToken}`,
  //       },
  //       withCredentials: true,
  //     }
  //   );
  //   setAgentReportData(response);
  // };


 
  let type = "";
  auth.roles?.includes("contact-admin") ? (type = "admin") : (type = "agent");

  const SidebarMenu = () => {
    let Menus = [];
    auth.roles?.includes("contact-admin")
      ? (Menus = Adminroutes)
      : (Menus = AgentRoutes);

    return (
      <>
        {Menus.map((Menu, index) => (
          <Link
            to={type + "/" + Menu.path}
            key={index}
            className={`flex  group rounded-md p-2 cursor-pointer ${
              props.currsidemenu == Menu.name ? "bg-[#293134]" : <></>
            } hover:bg-[#293134] text-gray-300 text-sm items-center gap-x-4 
              ${Menu.gap ? "mt-9" : "mt-2"} `}
            onClick={() => props.setCurrsidemenu(Menu.name)}
          >
            <li className="flex  rounded-md p-2 cursor-pointer  text-gray-300 text-sm items-center gap-x-4">
              <button className="group rounded-xl text-white">
                <Icon
                  icon={Menu.icon}
                  active={props.currsidemenu == Menu.name ? true : false}
                />
              </button>
              <span
                className={`${
                  !open && "hidden"
                } origin-left duration-200 text-l`}
              >
                {Menu.name}
              </span>
            </li>
          </Link>
        ))}
      </>
    );
  };

  return (
    <aside
      className={`flex-shrink-0 relative ${props.sidebaropen ? "" : "hidden"} ${
        !open ? "w-20" : "w-64"
      }  bg-sidenavbg border-r md:block`}
    >
      <img
        src={require("../assets/images/control.png")}
        className={` cursor-pointer absolute -right-3 mt-16 w-7 ring-sidenavbg
          rounded-full  ${!open && "rotate-180"}`}
        onClick={() => setOpen(!open)}
      />
      <div className="flex flex-col h-full">
        <nav
          aria-label="Main"
          className="flex-1 px-2 py-4 space-y-2 overflow-y-hidden hover:overflow-y-auto no-scrollbar"
        >
          <div className="flex gap-x-4 items-center mb-[50px]">
            <LogoIcon className={`cursor-pointer duration-500 `} />
            <h1
              className={`text-white origin-left font-medium text-xl duration-200 ${
                !open && "hidden"
              } `}
            >
              CONTACT CENTRE
            </h1>
          </div>
          <div>
            <ul>
              <SidebarMenu />
            </ul>
          </div>
        </nav>
        {/* <div className="flex-shrink-0 px-2 py-4 space-y-2">
        <button  type="button" className="flex items-center justify-center w-full px-4 py-2 text-sm text-white rounded-md bg-[#4caf50] hover:bg-primary-dark focus:outline-none focus:ring focus:ring-primary-dark focus:ring-offset-1 focus:ring-offset-white dark:focus:ring-offset-dark">
          <span aria-hidden="true">
            <svg className="w-4 h-4 mr-2" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M12 6V4m0 2a2 2 0 100 4m0-4a2 2 0 110 4m-6 8a2 2 0 100-4m0 4a2 2 0 110-4m0 4v2m0-6V4m6 6v10m6-2a2 2 0 100-4m0 4a2 2 0 110-4m0 4v2m0-6V4"></path>
            </svg>
          </span>
          <span className={`${!open && "hidden"}`}>DIAL PAD</span>
        </button>
      </div> */}
      </div>
    </aside>
  );
};

export default Sidebar;
