import { useState, useEffect } from "react";
import useSocket from "../hooks/useWebsocket";

const ReconnectingPopup = (props) => {
    const [isReady, val, send, disconnect] = useSocket();

    const closePopUp = () => {
        props.close(false)
    }
    return (
        <div
            id="defaultModal"
            className="fixed inset-0 flex items-center justify-center p-4 overflow-y-auto bg-black bg-opacity-50"
        >
            <div id="popup-modal" tabindex="-1" className="fixed inset-0 flex items-center justify-center p-4 overflow-y-auto bg-black bg-opacity-50">
                <div className="relative p-4 w-full max-w-md max-h-full">
                    <div className="relative bg-white rounded-lg shadow-sm dark:bg-gray-700">
                        <div className="p-4 md:p-5 text-center">
                            <svg className="mx-auto mb-4 text-gray-400 w-12 h-12 dark:text-gray-200" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 20 20">
                                <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M10 11V6m0 8h.01M19 10a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z" />
                            </svg>
                            <h3 className="mb-5 text-lg font-normal text-gray-800 dark:text-gray-700">Connection Loss!</h3>
                            <button data-modal-hide="popup-modal" type="button" className="text-white bg-red-600 hover:bg-red-800 focus:ring-4 focus:outline-none focus:ring-red-300 dark:focus:ring-red-800 font-medium rounded-lg text-sm inline-flex items-center px-5 py-2.5 text-center" onClick={closePopUp}>
                                Close
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default ReconnectingPopup;