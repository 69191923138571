import React, { useEffect } from "react";
import { ReactComponent as Cross } from "../../assets/images/svg/Cross.svg";
import { ReactComponent as Recall } from "../../assets/images/svg/ReCall.svg";

function Feedback01(props) {
  console.log("Feedback01  props", props.customer);

  console.log("Show Customer Number in Feedback PopUp  : ",props.showCustomerNo)

  return (
    <>
      <div className="grid grid-cols-2 space-x-4">
        <div className="col-span-1">
          <h1 className="text-gray-400 text-md">Customer Name</h1>
          <input
            type="text"
            key={props.customer.id}
            id="Answer"
            defaultValue={props.customer.customer_name || ""}
            className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg w-full h-12 p-2.5   "
            required
            readOnly
          />
        </div>
        <div className="col-span-1">
          <h1 className="text-gray-400 text-md">Number</h1>
          <input
            type="text"
            key={props.customer.id}
            id="Answer"
            defaultValue={
              props.showCustomerNo
                ? "NA" // If customerNumPermission is true, set default value to empty string
                : props.customer.customer_phone_number || "" // Otherwise, set it to customer_phone_number if it exists
            }
            className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg w-full h-12 p-2.5   "
            required
            readOnly
          />
        </div>
      </div>

      {props.customer.customer_details &&
        Object.keys(props.customer.customer_details).map((keyName, index) => (
          <div key={keyName}>
            <h1 className="text-gray-400 text-md">{keyName}</h1>
            {/* <input
              type="text"
              id="Answer"
              key={index}
              value={props.customer.customer_details[keyName] || ""}
              // className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg w-full h-20 p-2.5   "
              required
              readOnly
            /> */}
            <textarea
              id="message"
              name="message"
              key={index}
              rows="4"
              className="mt-1 block w-full p-3 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-blue-500 focus:border-blue-500 overflow-y-auto"
              defaultValue={props.customer.customer_details[keyName] || ""}
            />
          </div>
        ))}

      {/* <div>
        <h1 className="text-gray-400 text-md">Description</h1>
        <input
          type="text"
          id="Answer"
          className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg w-full h-32 p-2.5   "
          required
        />
      </div>
      <div>
        <h1 className="text-gray-400 text-md">Other Details</h1>
        <input
          type="text"
          id="Answer"
          className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg w-full h-32 p-2.5   "
          required
        />
      </div> */}

      <div className="flex place-content-between">
        <div className=""></div>
        <div className="">
          <button
            onClick={() => {
              props.setcurrfback(props.next);
            }}
            className="font-bold text-lg rounded-full w-36 h-12 mt-12 items-end"
            style={{
              backgroundImage:
                "linear-gradient(177.88deg, #AFD462 1.79%, #6BC698 49.97%, #0FB1E2 82.06%)",
              color: "white",
            }}
          >
            Next
          </button>
        </div>
      </div>
    </>
  );
}
export default Feedback01;
