import React, { useEffect, useState } from "react";
import axios from "axios";
import useAuth from "../../hooks/useAuth";
import Pagination from "../../components/Table/pagination";
import { ReactComponent as Edit } from "../../assets/images/svg/Edit.svg";
import { ReactComponent as Delete } from "../../assets/images/svg/Delete.svg";
import Swal from "sweetalert2";

const CampaignCustomers = () => {
    const { auth } = useAuth()

    const Initial_pagination = {
        next: "",
        previous: "",
        count: "",
        total_pages: "",
        current_page: 1,
        page_size: 5,
    };

    const [customerList, setCustomerList] = useState([])
    const [pagination, setPagination] = useState(Initial_pagination)

    const [isModalOpen, setIsModalOpen] = useState(false);
    const [selectedCustomer, setSelectedCustomer] = useState(null);
    const [customerName, setCustomerName] = useState("");
    const [customerPhoneNumber, setCustomerPhoneNumber] = useState("");
    const [status, setStatus] = useState("");
    const [campaign, setCampaign] = useState("")
    const [camplist, setCampList] = useState([]);
    const [searchText, setSearchText] = useState("");

    const editCustomer = (customer) => {
        setSelectedCustomer(customer);
        setCustomerName(customer.customer_name);
        setCustomerPhoneNumber(customer.customer_phone_number);
        setIsModalOpen(true);
    };

    const PaginationControl = (type, number) => {
        if (type == "page") {
            if (number <= pagination.total_pages) {
                fetchCampainCustomers(number);
            } else {
                fetchCampainCustomers(1);
            }
        } else if (type == "last") {
            fetchCampainCustomers(pagination.total_pages);
        } else if (type == "prev") {
            if (pagination.current_page > 1) {
                fetchCampainCustomers(pagination.current_page - 1);
            }
        } else if (type == "next") {
            if (pagination.current_page < pagination.total_pages) {
                fetchCampainCustomers(pagination.current_page + 1);
            }
        } else {
            fetchCampainCustomers(1);
        }
    };

    const fetchCampainCustomers = async (pagenumber) => {
        let postData = {
            user_id: auth?.user_details?.id,
            page: pagenumber,
            campaign: campaign || "",
            search_text: searchText || "",
            call_status: status || ""
        };

        let config = {
            headers: { "Content-Type": "application/json" },
        };

        await axios
            .get(
                process.env.REACT_APP_API_URL + "/contactcentre/campaign-customers/",
                {
                    params: postData,
                },
                config
            )
            .then((res) => {
                setCustomerList(res.data.results);
                setPagination((prevState) => {
                    return {
                        ...prevState,
                        total_pages: res.data?.total_pages || 0,
                        previous: res.data?.previous || null,
                        next: res.data?.next || null,
                        current_page: res.data?.current_page || 0,
                        count: res.data?.count || 0,
                        page_size: res.data?.page_size || 5,
                    };
                });
            });
    };

    const customerDelete = async (customer_id) => {
        Swal.fire({
            title: "Are you sure to Delete ?",
            text: "This customer will be deleted from your campaign",
            showCancelButton: true,
            confirmButtonColor: "#3085d6",
            cancelButtonColor: "#d33",
            confirmButtonText: "Delete",
        }).then(async (result) => {
            if (result.isConfirmed) {
                let config = {
                    headers: { "Content-Type": "application/json" },
                    data: { customer_id: customer_id }
                };
                await axios
                    .delete(
                        process.env.REACT_APP_API_URL + "/contactcentre/campaign-customers/",
                        config
                    )
                    .then((res) => {
                        Swal.fire(
                            'Deleted!',
                            'Customer has been deleted.',
                            'success'
                        );
                        fetchCampainCustomers(pagination.current_page)
                    })
            }
        })
    }

    const saveCustomer = async () => {
        let updatedCustomer = {
            customer_name: customerName,
            customer_phone_number: customerPhoneNumber,
            customer_id: selectedCustomer.id
        };

        let config = {
            headers: { "Content-Type": "application/json" },
        };

        await axios
            .post(
                process.env.REACT_APP_API_URL + "/contactcentre/campaign-customers/",
                updatedCustomer,
                config
            )
            .then(() => {
                Swal.fire(
                    "Updated!",
                    "Customer details have been updated.",
                    "success"
                );
                fetchCampainCustomers(pagination.current_page);
                setIsModalOpen(false);
            });
    };

    const fetchCampaignList = async () => {
        let post_data = {
            user_id: auth.user_details?.user,
            user_type: auth.user_details?.type,
        }

        try {
            const response = await axios.get(
                process.env.REACT_APP_API_URL + "/contactcentre/allcampaigns/",
                {
                    params: post_data,
                }
            );
            setCampList(response.data);
        } catch (err) {
            console.error(err);
        }
    }


    const SearchCustomer = () => {
        fetchCampainCustomers(1);
    };
    

    useEffect(() => {
        fetchCampainCustomers(1);
        fetchCampaignList();
    }, []);

    return (
        <>
            <div className="flex justify-between p-3 gap-4">
                <div className="flex justify-items-start gap-6">
                    <div className="">
                        <label htmlFor="table-search" className="sr-only">
                            Search
                        </label>
                        <div className="relative">
                            <div className="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
                                <svg
                                    className="w-5 h-5 text-gray-500 "
                                    fill="currentColor"
                                    viewBox="0 0 20 20"
                                    xmlns="http://www.w3.org/2000/svg"
                                >
                                    <path
                                        fillRule="evenodd"
                                        d="M8 4a4 4 0 100 8 4 4 0 000-8zM2 8a6 6 0 1110.89 3.476l4.817 4.817a1 1 0 01-1.414 1.414l-4.816-4.816A6 6 0 012 8z"
                                        clipRule="evenodd"
                                    ></path>
                                </svg>
                            </div>
                            <input
                                type="text"
                                id="table-search"
                                className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-80 pl-10 p-2.5  "
                                placeholder="Search for items"
                                onChange={(e) => {
                                    setSearchText(e.target.value);
                                }}
                            />
                        </div>
                    </div>
                    <div className="relative w-full flex space-x-2">
                        <select
                            className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-80 pl-10 p-2.5  "
                            onChange={(e) => {
                                setCampaign(e.target.value);
                            }}
                        >
                            <option value="">Select Campaign</option>
                            {camplist &&
                                camplist.map &&
                                camplist.map((item) => (
                                    <option key={item.id} value={item.id}>
                                        {item.name}
                                    </option>
                                ))}
                        </select>
                    </div>

                    <div className="relative w-full flex space-x-2">
                        <select
                            className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-80 pl-10 p-2.5  "
                            onChange={(e) => {
                                setStatus(e.target.value);
                            }}
                        >
                            <option value="">Select Call Status</option>
                            <option value={"called"}>Called</option>
                            <option value={"notcalled"}>Not Called</option>
                        </select>
                    </div>

                    <div className="space-x-2">
                        <button
                            className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded"
                            onClick={SearchCustomer}
                        >
                            Submit
                        </button>
                    </div>

                </div>
            </div>
            <div className="overflow-x-auto p-4 relative shadow-md sm:rounded-lg">
                <table className="w-full text-sm text-left text-gray-800 ">
                    <thead className="text-xs text-center text-gray-700 uppercase bg-gray-50 d">
                        <tr>
                            <th scope="col" className="py-3 px-6">
                                Sl-No
                            </th>
                            <th scope="col" className="py-3 px-6">
                                Customer
                            </th>
                            <th scope="col" className="py-3 px-6">
                                Campaign
                            </th>
                            <th scope="col" className="py-3 px-6">
                                Phone No
                            </th>
                            <th scope="col" className="py-3 px-6">
                                Status
                            </th>
                            <th scope="col" className="py-3 px-6">
                                Actions
                            </th>
                        </tr>
                    </thead>
                    <tbody className="text-center">
                        {customerList.map((customer, index) => (
                            <tr className="bg-white border-b hover:bg-gray-50 ">
                                <td className="py-4 px-6">
                                    {index +
                                        1 +
                                        (pagination?.current_page - 1) * pagination?.page_size}
                                </td>
                                <td className="py-4 px-6">{customer.customer_name}</td>
                                <td className="py-4 px-6">{customer.campaign_name}</td>
                                <td className="py-4 px-6">{customer.customer_phone_number}</td>
                                <td className="py-4 px-6">{customer.callStatus}</td>
                                <td className="py-4 px-6">
                                    <div className="flex justify-center items-center">
                                        <div className="flex rounded-md p-2 cursor-pointer hover:bg-light-white text-gray-300" onClick={() => editCustomer(customer)}>
                                            <Edit
                                                className={`width="26" height="26" fill="blue"`}
                                                viewBox="0 0 26 26"
                                                fill="green"
                                                cx="8.5"
                                                cy="8.5"
                                                r="6"
                                                fillRule="evenodd"
                                                fillOpacity=".8"
                                            />
                                        </div>
                                        <div
                                            onClick={() => {
                                                customerDelete(customer.id);
                                            }}
                                            className="flex rounded-md p-2 cursor-pointer hover:bg-light-white text-gray-300">
                                            <Delete
                                                className={`width="26" height="26" fill="blue"`}
                                                viewBox="0 0 26 26"
                                                fill="red"
                                                cx="8.5"
                                                cy="8.5"
                                                r="6"
                                                fillRule="evenodd"
                                                fillOpacity=".8"
                                            />
                                        </div>
                                    </div>

                                </td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>

            {isModalOpen && (
                <div className="fixed inset-0 bg-gray-600 bg-opacity-50 flex justify-center items-center">
                    <div className="bg-white p-6 rounded-md shadow-lg w-1/3">
                        <h2 className="text-xl font-bold mb-4">Edit Customer</h2>
                        <div className="mb-4">
                            <label className="block text-sm font-medium text-gray-700">Name</label>
                            <input
                                type="text"
                                className="mt-1 p-2 block w-full border border-gray-300 rounded-md"
                                value={customerName}
                                onChange={(e) => setCustomerName(e.target.value)}
                            />
                        </div>
                        <div className="mb-4">
                            <label className="block text-sm font-medium text-gray-700">Phone Number</label>
                            <input
                                type="text"
                                className="mt-1 p-2 block w-full border border-gray-300 rounded-md"
                                value={customerPhoneNumber}
                                onChange={(e) => setCustomerPhoneNumber(e.target.value)}
                            />
                        </div>
                        <div className="flex justify-end">
                            <button className="bg-red-500 text-white px-4 py-2 rounded mr-2" onClick={() => setIsModalOpen(false)}>Cancel</button>
                            <button className="bg-blue-500 text-white px-4 py-2 rounded" onClick={saveCustomer}>Save</button>
                        </div>
                    </div>
                </div>
            )}

            <div className="p-8">
                <Pagination
                    pagination_data={pagination}
                    PaginationControl={PaginationControl}
                />
            </div>
        </>
    );

}

export default CampaignCustomers;