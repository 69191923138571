import React, { useEffect, useState } from "react";
import { ReactComponent as AnswerdIcon } from "../../assets/icons/answerdcalls.svg";
import { ReactComponent as AvereageresponseIcon } from "../../assets/icons/avereageresponse.svg";
import { ReactComponent as TotalcallsIcon } from "../../assets/icons/totalcalls.svg";
import { ReactComponent as TotalCallDurationIcon } from "../../assets/icons/totalduration.svg";
import { ReactComponent as Refresh } from "../../assets/icons/refresh.svg";
import DonutChart from "../../components/Charts/DonutChart";
import AgentChart from "../../components/Charts/AgentChart";
import CampaignList from "./CampaignList";
import CampaignReport from "../Reports/CampaignReport";
import { CALL_COUNT, CAMPAGIN_DATA } from "../../utils/ApiUrls";
import useAuth from "../../hooks/useAuth";
import EventCalendar from "../../components/Calender/Calender";

import { CamptheadData } from "../../utils/Data";
import axios from "axios";
const Dashboard = () => {
  const { auth } = useAuth();
  const [refresh, setRefresh] = useState(false);

  const [dashdata, setdashdata] = useState({
    total_calls: 0,
    answerd_calls: 0,
    call_duration: 0,
    avarege_response: 0,
    label: [],
    called_data: [],
    tobe_called: [],
    answerd_percentage: [],
  });

  const fetchDashboardData = async () => {
    let user_id = auth?.user_details?.useragent;
    let config = {
      headers: { "Content-Type": "application/json" },
    };

    await axios
      .get(
        process.env.REACT_APP_API_URL +
          "/contactcentre/call_count/" +
          user_id +
          "/" +
          "agent/",
        config
      )
      .then((res) => {
        setdashdata((prev) => {
          return {
            ...prev,
            total_calls: res.data.total_calls,
            answerd_calls: res.data.answerd_calls,
            call_duration: res.data.call_duration,
            avarege_response: res.data.avarege_response,

            label: res.data.label,
            called_data: res.data.called_data,
            tobe_called: res.data.tobe_called,
            answerd_percentage: res.data.answerd_percentage,
          };
        });
      })
      .then(console.log("=============data loaded========="))
      .catch((err) => console.log(err));
  };
  useEffect(() => {
    fetchDashboardData();
    setRefresh(false);
  }, [refresh]);

  const RrefreshData = () => {
    setRefresh(true);
  };

  return (
    <div className="gap-2" key={refresh}>
      {/* Dashboard Data grids */}
      <div className="grid grid-cols-1 h-[87px] md:gap-0 divide-x-[2px] divide-blue-[#BFC7CD] gap-4 lg:grid-cols-2 xl:grid-cols-4">
        <div className="flex items-center gap-2 justify-start p-4 bg-white rounded-md er">
          <div>
            <span>
              <button className="p-3 transition-colors duration-200 rounded-lg bg-[#E7F8F2]">
                <TotalcallsIcon
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                />
              </button>
            </span>
          </div>
          <div>
            <span className="text-[20px] font-[700]">
              {dashdata.total_calls}
            </span>
            <h6 className="text-xs font-medium leading-none tracking-wider text-gray-500 ">
              Total Calls
            </h6>
          </div>
        </div>
        <div className="flex items-center gap-2 justify-start p-4 bg-white rounded-md er">
          <div>
            <span>
              <button className="p-3 transition-colors duration-200 rounded-lg bg-[#FFEEF1]">
                <AnswerdIcon xmlns="http://www.w3.org/2000/svg" fill="none" />
              </button>
            </span>
          </div>
          <div>
            <span className="text-[20px] font-[700]">
              {dashdata.answerd_calls}
            </span>
            <h6 className="text-xs font-medium leading-none tracking-wider text-gray-500 ">
              Answered Calls
            </h6>
          </div>
        </div>
        <div className="flex items-center gap-2 justify-start p-4 bg-white rounded-md er">
          <div>
            <span>
              <button className="p-3 transition-colors duration-200 rounded-lg bg-[#F1EDF8]">
                <TotalCallDurationIcon
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                />
              </button>
            </span>
          </div>
          <div>
            <span className="text-[20px] font-[700]">
              {dashdata.call_duration}
            </span>
            <h6 className="text-xs font-medium leading-none tracking-wider text-gray-500 ">
              Total Calls Duration
            </h6>
          </div>
        </div>
        <div className="flex items-center gap-2 justify-center p-4 bg-white rounded-md er">
          <div className="flex items-center">
            {/* <h6 className="text-md font-medium leading-none tracking-wider text-gray-700 ">
              Refresh
            </h6>
            <span> */}
            {/* <button
                className="p-3 transition-colors duration-200 rounded-lg "
                onClick={RrefreshData}
              >
                <Refresh
                  xmlns="http://www.w3.org/2000/svg"
                  className="w-6 h-6"
                />
              </button> */}
            {/* </span> */}

            <button
              type="button"
              className="flex items-center text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 me-2 mb-2 "
              onClick={RrefreshData}
            >
              <Refresh xmlns="http://www.w3.org/2000/svg" className="w-4 h-4" />
              <h5 className="ml-2 text-white text-lg">Refresh</h5>
            </button>
          </div>
        </div>
        {/* <div className="flex items-center gap-2 justify-start p-4 bg-white rounded-md er">
            <div>
              <span>
                <button className="p-3 transition-colors duration-200 rounded-lg bg-[#FFEEF0]">
                    <AvereageresponseIcon xmlns="http://www.w3.org/2000/svg" className='fill-black' />
                </button>
              </span>                      
            </div>
            <div>
            <span className="text-[20px] font-[700]">{dashdata.avarege_response }</span>
              <h6 className="text-xs font-medium leading-none tracking-wider text-gray-500 ">
              Average Response Time
              </h6>
            </div>
          </div> */}
      </div>

      <div className="grid grid-cols-1 pt-4 space-y-6 lg:gap-4 lg:space-y-0 lg:grid-cols-3">
        <div className="bg-white rounded-md er">
          <div className="relative p-2 h-76">
            <EventCalendar />
          </div>
        </div>
        <div className="col-span-2 bg-white rounded-md er">
          <div className="h-[29rem]">
            <div className="bg-back-white p-4 rounded-md">
              <div className="flex items-center justify-between">
                <h4 className="text-lg font-[600] text-black">
                  Active Campaigns
                </h4>
                {/* <button className="bg-blue-500 hover:bg-blue-700 w-[80px] h-8 text-[13px] text-white font-bold py-2 px-4 rounded-full">
                    see more
                  </button> */}
              </div>
              <CampaignList theadData={CamptheadData} user_type="agent" />
            </div>
          </div>
        </div>
      </div>

      {/* Agent and DonutChart */}
      <div className="grid grid-cols-1 pt-4 space-y-6 lg:gap-4 lg:space-y-0 lg:grid-cols-3">
        <div className="col-span-2 p-2 bg-white rounded-md er">
          <div className="flex items-center justify-between">
            <h4 className="text-lg font-[600] text-black">
              Campaign Bar Chart
            </h4>
            <div className="flex items-center"></div>
          </div>
          <div className="relative h-72 mt-4">
            <AgentChart
              label={dashdata?.label || []}
              called={dashdata?.called_data || []}
              tobe_called={dashdata?.tobe_called || []}
            />
          </div>
        </div>
        <div className="bg-white p-4 rounded-md">
          <div className="flex items-center justify-between">
            <h4 className="text-lg font-[600] text-black">
              Answered Percentage
            </h4>
            <div className="flex items-center"></div>
          </div>
          <div className="relative mt-4 h-72">
            <DonutChart
              call_data={dashdata?.answerd_percentage || [0, 0]}
              className="w-10 h-10"
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Dashboard;
